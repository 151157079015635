import React from 'react';

const ContactForm = () => {
  return (
    <div className="max-w-md">
      <div className="pb-4">
        Reach out at <a href="mailto:hello@petraflucka.com">hello@petraflucka.com</a> or drop me a message through the
        contact form below.
        <br />I can’t wait to hear what you’ve been cooking up.
      </div>
      <h2 className="pb-6">Contact me!</h2>
      <form action="https://getform.io/f/cf2981dc-f869-45ae-9ef5-65264ee0ccbf" method="POST">
        <div className="flex flex-col mb-4">
          <label className="mb-2" htmlFor="email">
            Email
          </label>
          <input id="email" className="border py-2 px-3" type="text" name="email" />
        </div>

        <div className="flex flex-col mb-4">
          <label className="mb-2" htmlFor="name">
            Name
          </label>
          <input id="name" className="border py-2 px-3" type="text" name="name" />
        </div>

        <div className="flex flex-col mb-4">
          <label className="mb-2" htmlFor="message">
            Message
          </label>
          <textarea id="message" className="border py-2 px-3" name="message" />
        </div>
        <button
          className="bg-gray-500 hover:bg-gray-700 transition duration-300 text-white uppercase mx-auto px-8 py-2 rounded"
          type="submit"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
